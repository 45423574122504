// React imports.
import React from "react";
import { Loader } from "@progress/kendo-react-indicators";

const LoadingPanel = () => {
  return (
    <div className="flex justify-center items-center loader-div" > 
        <Loader size="large" type="infinite-spinner"></Loader>               
    </div>
  );
};

export default LoadingPanel;